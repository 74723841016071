<template>
  <v-row>
    <v-col cols="12">
      <payment-list></payment-list>
    </v-col>
  </v-row>
</template>

<script>
import PaymentList from './components/PaymentList.vue'

export default {
  components: {
    PaymentList,
  },
  setup() {
    return {}
  },
}
</script>
