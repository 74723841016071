<template>
  <div>
    <p class="headline black--text font-weight-bold">Menunggu Pembayaran</p>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row class="mt-0" no-gutters v-if="itemData.length > 0">
        <div class="col-md-12 col-lg-12">
          <v-card class="overflow-hidden elevation-1 mb-5"  v-for="list in itemData" :key="list.cartId">
            <!-- DESKTOP -->
            <v-card-text v-if="!isMobile()">
              <div class="row">
                <div class="col-md-6">
                  <p class="mb-0 font-weight-bold">INVOICE <span class="font-weight-medium">{{ list.invoice }}</span></p>
                </div>
                <div class="col-md-6">
                  <div class="d-flex justify-end">
                    <p class="font-weight-bold mb-0"><span class="font-weight-medium ml-1">{{ list.date_added | dateFormat2}}</span></p>
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="d-flex justify-end">
                    <p class="font-weight-bold mb-0">Bayar Sebelum <strong class="error--text ml-1">{{ list.due_date | dateFormat}}</strong></p>
                  </div>
                </div> -->
              </div>
              <v-divider class="mb-3 mt-2"></v-divider>
              <div class="row">
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Nama Paket</p>
                </div>
                <!-- <div class="col-md-1">
                  <p class="d-flex align-center mb-0">Qty</p>
                </div> -->
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Jenis Paket</p>
                </div>
                <div class="col-md-4">
                  <p class="d-flex align-center mb-0">Format Konten</p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Level Influencer</p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Total Influencer</p>
                </div>
                <!-- <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Total Followers</p>
                </div> -->
              </div>
              <div class="row mt-0"  v-for="listPackage in list.order_detail" :key="listPackage.id">
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.name }}</span>
                  </p>
                </div>
                <!-- <div class="col-md-1">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.qty }}</span>
                  </p>
                </div> -->
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.description | labelFormat}}</span>
                  </p>
                </div>
                <div class="col-md-4">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.content | labelFormat}}</span>
                  </p>
                </div>
                <div class="col-md-2 d-flex align-center">
                  <p class=" mb-0 text-capitalize font-weight-bold body-1 black--text">{{ listPackage.level | levelFormat}}
                  </p>
                </div>
                <div class="col-md-2 d-flex align-center">
                  <p class=" mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.qty_influencer }}</span>
                  </p>
                </div>
              </div>
              <v-divider class="mb-3 mt-3"></v-divider>
              <div class="row">
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Bank Pembayaran</p>
                  <p class="d-flex align-center mb-0">
                    <v-img class="" src="@/assets/images/logos/bank_bca.png" max-height="40" max-width="60"></v-img>
                  <span class="ml-1 text-capitalize">{{ list.description }}</span></p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Metode Pembayaran</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ list.paymentMethod }}</span>
                  </p>
                </div>
                <div class="col-md-4">
                  <p class="d-flex align-center mb-0">Nama Rekening</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">Sosial Media Kreatif PT</span>
                  </p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Nomor Rekening</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ list.norek }}</span>
                  </p>
                </div>
                <!-- <div class="col-md-1">
                  <v-divider
                    vertical
                  ></v-divider>
                </div> -->
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Total Pembayaran</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 secondary--text">{{ list.total_plus_code | numberFormat }}</span>
                  </p>
                </div>
              </div>
              <br>
              <v-divider class="mb-3"></v-divider>
              <div class="row">
                <div class="col-md-6">
                  <p class="font-weight-bold mb-0 pt-2">Bayar Sebelum <strong class="error--text ml-1">{{ list.due_date | dateFormat}}</strong></p>
                </div>
                <div class="col-md-6">
                  <v-btn @click="confirmPayment(list.invoice,list.total_plus_code)" color="primary" class="text-capitalize" style="float:right"><i class="fab fa-whatsapp mr-3"></i> Konfirmasi Pembayaran</v-btn>
                </div>
              </div>
            </v-card-text>
            <!-- MOBILE -->
            <v-card-text v-else>
              <div class="row">
                <div class="col-sm-12">
                  <p class="mb-0 font-weight-bold">INVOICE <span class="font-weight-medium">{{ list.invoice }}</span></p>
                  <p class="font-weight-bold mb-0"><span class="caption">{{ list.date_added | dateFormat2}}</span></p>
                </div>
              </div>
              <v-divider class="mb-3 mt-2"></v-divider>
              <div class="row"  v-for="listPackage in list.order_detail" :key="listPackage.id">
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Nama Paket</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold black--text">{{ listPackage.packages.name }}</span>
                  </p>
                </div>
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Jenis Paket</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold black--text">{{ listPackage.packages.description | labelFormat}}</span>
                  </p>
                </div>
                <div class="col-md-4 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Format Konten</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold black--text">{{ listPackage.packages.content | labelFormat}}</span>
                  </p>
                </div>
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Level Influencer</p>
                  <p class=" mb-0 text-capitalize font-weight-bold black--text">{{ listPackage.level | levelFormat}}
                  </p>
                </div>
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Total Influencer</p>
                  <p class=" mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.qty_influencer }}</span>
                  </p>
                  <v-divider class="mb-1 mt-3"></v-divider>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Bank Pembayaran</p>
                  <p class="d-flex align-center mb-0">
                    <v-img class="" src="@/assets/images/logos/bank_bca.png" max-height="40" max-width="60"></v-img>
                  <span class="ml-1 text-capitalize">{{ list.description }}</span></p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Metode Pembayaran</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ list.paymentMethod }}</span>
                  </p>
                </div>
                <div class="col-md-4">
                  <p class="d-flex align-center mb-0">Nama Rekening</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">Sosial Media Kreatif PT</span>
                  </p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Nomor Rekening</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ list.norek }}</span>
                  </p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Total Pembayaran</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 secondary--text">{{ list.total_plus_code | numberFormat }}</span>
                  </p>
                </div>
              </div>
              <br>
              <v-divider class="mb-3"></v-divider>
              <div class="row">
                <div class="col-md-6 text-center">
                  <p class="font-weight-bold mb-0 pt-2">Bayar Sebelum <br><strong class="error--text ml-1">{{ list.due_date | dateFormat}}</strong></p>
                </div>
                <div class="col-md-6">
                  <v-btn block @click="confirmPayment(list.invoice,list.total_plus_code)" color="primary" class="text-capitalize" style="float:right"><i class="fab fa-whatsapp mr-3"></i> Konfirmasi Pembayaran</v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <!-- <div class="col-md-4">
          <v-card class="ml-5 elevation-1">
            <v-card-title>Ringkasan Item</v-card-title>
            <v-list two-line>
              <template>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title >Total Harga (<span>{{this.itemData.length}} </span>Item)</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text class="subtitle-2">Rp {{total | numberFormat}}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title >Biaya Layanan</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text class="subtitle-2">Rp {{ serviceFee | numberFormat}}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">Total Tagihan</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text><h2>Rp {{totalPrice | numberFormat}}</h2></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div class="row ml-1 mr-1">
              <div class="col-md-12">
              <v-btn
                color="primary"
                class="text-capitalize"
                block
                @click="choosePayment"
                >
                <i class="fas fa-credit-card text-24 mr-2"></i>
                Pilih Pembayaran
              </v-btn>
              </div>
          </div>
          </v-card>
        </div> -->
      </v-row>
      <div v-else>
        <div class="d-flex justify-center mt-5">
          <v-card width="600">
            <v-card-title class="text-center">Oops! Anda belum Membuat Campaign</v-card-title>
            <v-card-text><p>Daftar pembayaran Anda akan ditampilkan disini.</p></v-card-text>
            <v-card-actions class="d-flex justify-center mt-0">
              <v-btn @click="goStarted" class="text-capitalize" color="primary">Buat Campaign Sekarang</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  setup() {
    return{
      totalPrice:0,
      itemData:[],
      isLoading:false,
      total:0,
      serviceFee:0,
      itemDetail:[]
    }
  },
  mounted(){
    this.loadOrders()
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadOrders(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getPendingOrders', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.itemData = response.data.data
            console.log(this.itemData)
            for(let a = 0; a < this.itemData.length; a++){
              if(this.itemData[a].payment_method === 'bca_transfer'){
                this.itemData[a].paymentMethod = 'BCA Transfer';
                this.itemData[a].norek = '2333111411'
                this.itemData[a].date_added = new Date(this.itemData[a].date_added)
                this.itemData[a].due_date = new Date(this.itemData[a].due_date)
              } else if(this.itemData[a].payment_method === 'bni_transfer'){
                this.itemData[a].paymentMethod = 'BNI Transfer';
                this.itemData[a].norek = '4190074868';
                this.itemData[a].date_added = new Date(this.itemData[a].date_added)
                this.itemData[a].due_date = new Date(this.itemData[a].due_date)
              }
              axios.get(
                process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getOrderDetailDistinct/'+this.itemData[a].id, {headers: {
                  'Authorization': 'Bearer '+ token
                }}).then(response => {
                  if(response.status === 200){
                    this.itemData[a].order_detail = response.data.data
                    // console.log(this.itemData[a].order_detail)
                    for(let b = 0; b < this.itemData[a].order_detail.length; b++){
                    //   this.itemData[a].order_detail[a].date_added = new Date(this.itemData[a].order_detail[a].date_added)
                    //   this.itemData[a].order_detail[a].upload_date = this.itemData[a].order_detail[a].upload_date.slice(0,10)
                      // for(let b=0; b < this.itemData[a].order_detail.length; b++){
                        // this.itemData[a].num = a+1
                        this.itemData[a].order_detail[b].qty_influencer = this.itemData[a].order_detail[b].qty
                        this.itemData[a].order_detail[b].qty = this.itemData[a].order_detail[b].type === 'custom' ? 1 : parseInt(this.itemData[a].order_detail[b].qty) / parseInt(this.itemData[a].order_detail[b].packages.qty)
                        // this.itemData[a].order_detail[b].qty = this.itemData[a].order_detail[b].type
                        
                        if(this.itemData[a].order_detail[b].type === 'custom'){
                          this.itemData[a].order_detail[b].packages.description = this.itemData[a].order_detail[b].packages.type
                          this.itemData[a].order_detail[b].packages.type = this.itemData[a].order_detail[b].packages.level
                          this.itemData[a].order_detail[b].packages.content = this.itemData[a].order_detail[b].packages.format
                          this.itemData[a].order_detail[b].packages.name = this.itemData[a].order_detail[b].packages.name.slice(0,6)
                        }
                        // this.level.push(this.levelFormat(this.itemDetail[a].level))
                      // }
                    }
                    // this.level = [ ...new Set(this.level) ]
                    setTimeout(() => { 
                      this.isLoading = false
                    }, 500);
                  }
                }).catch(error => {
                  console.log(error)
              });
            }
            setTimeout(() => { 
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    confirmPayment(invoice, price){
      window.open('https://wa.me/6285715160130?text=Halo Admin, Saya telah melakukan pembayaran sebesar *Rp '+ this.numberFormat(price) +'* dengan nomor invoice *'+invoice+ '*. \n Terima kasih', '_blank');
    },
    goStarted(){
      this.$router.push({name:'campaign'}); 
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    },
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    }
  },
})
</script>